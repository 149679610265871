const CreateInternalApplicationMutation = `
  mutation (
    $jobId: Int!
    $fullName: String!
    $phoneNumber: String!
    $email: String!
    $resumeFile: File
    $headline: String
    $coverLetter: String
    $termsAndConditions: Boolean
    $profileLevel: ProfileProfileLevel
    $birthday: JSON
    $languages: [JSON!]
    $nationality: String
    $willingToRelocate: Boolean
    $noticeToPeriodDays: String
    $typeOfExpectedSalary: ProfileTypeOfExpectedSalary
    $typeOfCurrentSalary: ProfileTypeOfCurrentSalary
    $expectedSalary: Int
    $expectedSalaryCurrency: String
    $currentSalary: Int
    $currentSalaryCurrency: String
    $totalYearsOfExp: Int
    $customFields: [JSON!]
  ) {
    internalApplicantsCreate(
      input: {
        jobId: $jobId
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        resumeFile: $resumeFile
        headline: $headline
        coverLetter: $coverLetter
        termsAndConditions: $termsAndConditions
        profileLevel: $profileLevel
        birthday: $birthday
        languages: $languages
        nationality: $nationality
        willingToRelocate: $willingToRelocate
        noticeToPeriodDays: $noticeToPeriodDays
        typeOfExpectedSalary: $typeOfExpectedSalary
        typeOfCurrentSalary: $typeOfCurrentSalary
        expectedSalaryCurrency: $expectedSalaryCurrency
        currentSalaryCurrency: $currentSalaryCurrency
        currentSalary: $currentSalary
        expectedSalary: $expectedSalary
        totalYearsOfExp: $totalYearsOfExp
        customFields: $customFields
      }
    ) {
      profile {
        id
      }
    }
  }
`

export default CreateInternalApplicationMutation
